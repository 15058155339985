import {
  List,
  Datagrid,
  TextField,
  ImageField,
  EditButton,
  DeleteButton,
  ReferenceField,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  Create,
  TopToolbar,
  ListButton,
  SelectInput,
  WithRecord,
  useDataProvider,
} from 'react-admin';
import { useQuery } from 'react-query';

interface ModuleProps {
  id: number;
  guid: string;
  title: string;
  service_provider_id: number;
  description: string;
  image_uri: string;
  information_file_uri: string;
  resource_access_uri: string;
  ark_id: string;
  has_role_access_control: true;
  is_allowed_for_gar: string;
  is_allowed_out_of_gar: string;
  is_allowed_for_classroom_account: string;
}

const postFilters = [<TextInput label="Search" source="terms" alwaysOn />];

export const ModuleList = (props: ModuleProps) => {
  return (
    <List {...props} filters={postFilters}>
      <Datagrid>
        <TextField source="id" />
        <WithRecord
          label="Image"
          render={(record) => (
            <>
              {record.image_uri ? (
                <ImageField
                  source="image_uri"
                  sx={{
                    '& img': {
                      maxWidth: 100,
                      maxHeight: 50,
                      objectFit: 'contain',
                    },
                  }}
                />
              ) : (
                <img
                  src="/assets/images/placeholder.svg"
                  alt="Logo manquant"
                  height="50"
                />
              )}
            </>
          )}
        />
        <TextField source="title" />
        <ReferenceField
          source="service_provider_id"
          reference="admin/service_providers"
          link={false}
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

const EditActions = () => (
  <TopToolbar>
    <ListButton label="Retour à la liste" />
  </TopToolbar>
);

export const ModuleCreate = () => {
  const dataProvider = useDataProvider();
  const { data: serviceProviders } = useQuery(
    ['admin/service_providers', 'getList'],
    () =>
      dataProvider.getList('admin/service_providers', {
        pagination: { page: 0, perPage: 999999 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      }),
  );

  return (
    <Create actions={<EditActions />}>
      <SimpleForm>
        <TextInput fullWidth source="title" required />
        <TextInput fullWidth source="description" />
        {serviceProviders && (
          <SelectInput
            fullWidth
            required
            source="service_provider_id"
            choices={serviceProviders.data}
            optionText="service_name"
          />
        )}
        <TextInput fullWidth source="image_uri" />
        <TextInput fullWidth source="information_file_uri" />
        <TextInput fullWidth source="resource_access_uri" />
        <TextInput fullWidth source="ark_id" />
        <BooleanInput fullWidth source="has_role_access_control" />
        <BooleanInput fullWidth source="is_allowed_for_gar" />
        <BooleanInput fullWidth source="is_allowed_out_of_gar" />
        <BooleanInput fullWidth source="is_allowed_for_classroom_account" />
      </SimpleForm>
    </Create>
  );
};

export const ModuleEdit = () => {
  const dataProvider = useDataProvider();
  const { data: serviceProviders } = useQuery(
    ['admin/service_providers', 'getList'],
    () =>
      dataProvider.getList('admin/service_providers', {
        pagination: { page: 0, perPage: 999999 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      }),
  );

  return (
    <Edit actions={<EditActions />}>
      <SimpleForm>
        <TextInput fullWidth source="id" disabled />
        <TextInput fullWidth source="title" required />
        <WithRecord
          label="Image"
          render={(record) => (
            <>
              {record.image_uri ? (
                <ImageField
                  source="image_uri"
                  sx={{
                    '& img': {
                      maxWidth: 200,
                      maxHeight: 100,
                      objectFit: 'contain',
                    },
                  }}
                />
              ) : (
                <img
                  src="/assets/images/placeholder.svg"
                  alt="Logo manquant"
                  height="100"
                />
              )}
            </>
          )}
        />
        <TextInput fullWidth source="image_uri" />
        <TextInput fullWidth source="description" />
        {/* disabled just like update on service_provider_id update (put) on api route */}
        {serviceProviders && (
          <SelectInput
            disabled
            source="service_provider_id"
            choices={serviceProviders.data}
            optionText="service_name"
          />
        )}
        <TextInput fullWidth source="information_file_uri" />
        <TextInput fullWidth source="resource_access_uri" />
        <TextInput fullWidth source="ark_id" />
        <BooleanInput fullWidth source="has_role_access_control" />
        <BooleanInput fullWidth source="is_allowed_for_gar" />
        <BooleanInput fullWidth source="is_allowed_out_of_gar" />
        <BooleanInput fullWidth source="is_allowed_for_classroom_account" />
      </SimpleForm>
    </Edit>
  );
};
