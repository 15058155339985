import { stringify } from 'query-string';
import {
  fetchUtils,
  DataProvider,
  GetListParams,
  DeleteParams,
  CreateParams,
  UpdateManyParams,
  UpdateParams,
  GetManyReferenceParams,
  GetManyParams,
  GetOneParams,
} from 'ra-core';

/**
 * Maps react-admin queries to Learning Connect API
 *
 * @example
 *
 * getList     => GET http://my.api.url/modules?sort=['title','ASC']&page=1&limit=10
 * getOne      => GET http://my.api.url/modules/123
 * getMany     => GET http://my.api.url/modules?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/modules/123
 * create      => POST http://my.api.url/modules
 * delete      => DELETE http://my.api.url/modules/123
 *
 */

export const dataProvider = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
): DataProvider => ({
  getList: (resource: string, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      page: page,
      limit: perPage,
      terms: params?.filter['terms'],
    };
    let url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {};
    return httpClient(url, options).then(({ headers, json }) => {
      let data = { ...json };
      if (json?.results) {
        data = [...json.results];
      }
      if (resource === 'admin/spaces') {
        // cas particulier de l'api de 'admin/spaces' qui n'a pas d'id...
        data = data.map((result: any) => ({ ...result, id: result.space_key }));
      }
      return {
        data: data,
        total: json.total_count,
      };
    });
  },

  getOne: (resource: string, params: GetOneParams) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => {
      return {
        data: {
          ...json,
          id: resource === 'admin/spaces' ? json.space_key : json.id,
        },
      };
    });
  },

  getMany: (resource: string, params: GetManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => {
      let data = { ...json };
      if (json.results) {
        data = [...json.results];
      }
      return { data: data };
    });
  },

  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      page: page,
      limit: perPage,
      filter: JSON.stringify({
        // ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {};
    return httpClient(url, options).then(({ headers, json }) => {
      let data = { ...json };
      if (data.results) {
        data = { ...data.results };
      }
      return {
        data: {
          ...data,
          id: resource === 'admin/spaces' ? data.space_key : data.id,
        },
        total: json.total_count,
      };
    });
  },

  update: (resource: string, params: UpdateParams) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      if (!json) {
        return { data: params.data };
      }
      return { data: json };
    });
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource: string, params: UpdateManyParams) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource: string, params: CreateParams) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      return {
        data: {
          ...json,
          id: resource === 'admin/spaces' ? json.space_key : json.id,
        },
      };
    });
  },

  delete: (resource: string, params: DeleteParams) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => {
      if (!json) {
        return { data: {} };
      }
      return { data: json };
    });
  },

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource: string, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        }),
      ),
    ).then((responses) => ({
      data: responses.map(({ json }) => json.id),
    })),

  /* CUSTOM METHOD for endpoints like GET /api/admin/spaces/{spaceKey}/modules ****/
  getListFrom: (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      page: page,
      limit: perPage,
      // filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {};

    return httpClient(url, options).then(({ headers, json }) => {
      return json;
    });
  },

  updateListFrom: (resource: string, params: { data: any }) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      if (!json) {
        return { data: params.data };
      }
      return { data: json };
    });
  },
});

export default dataProvider;
