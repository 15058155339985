import { PropsWithChildren } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  Create,
  TopToolbar,
  ListButton,
  BooleanInput,
  NumberInput,
} from 'react-admin';

interface AccountProps {
  id: number;
  user_id: number;
  structure_id: number;
  is_structure_admin: boolean;
  is_teacher: boolean;
  is_learner: boolean;
}

// *** NO ENDPOINT TO LIST ACCOUNTS
// const postFilters = [<TextInput label="Search" source="terms" alwaysOn />];
export const AccountList = (props: AccountProps) => {
  return (
    <div>
      Accounts cannot be listed.
      <br />
      Please report to users section
    </div>
    // <List {...props} filters={postFilters}>
    //   <Datagrid>
    //     <NumberField source="id" />
    //     <NumberField source="user_id" />
    //     <NumberField source="structure_id" />
    //     <BooleanField fullWidth source="is_structure_admin" />
    //     <BooleanField fullWidth source="is_teacher" />
    //     <BooleanField fullWidth source="is_learner" />
    //     <EditButton />
    //     <DeleteButton />
    //   </Datagrid>
    // </List>
  );
};

const EditActions = () => (
  <TopToolbar>
    <ListButton label="Retour à la liste" />
  </TopToolbar>
);

export const AccountCreate = (
  props: PropsWithChildren<{ user_id: number }>,
) => {
  return (
    <Create actions={<EditActions />}>
      {/* {props.title && (
        <Typography component="h2" variant="h6">
          {props.title}
        </Typography>
      )} */}
      <SimpleForm>
        <NumberInput source="id" />
        <NumberInput source="user_id" defaultValue={props.user_id} />
        <NumberInput source="structure_id" />
        <BooleanInput fullWidth source="is_structure_admin" />
        <BooleanInput fullWidth source="is_teacher" />
        <BooleanInput fullWidth source="is_learner" />
      </SimpleForm>
    </Create>
  );
};

export const AccountEdit = () => {
  return (
    <Edit actions={<EditActions />}>
      <SimpleForm>
        <TextInput fullWidth source="first_name" />
        <TextInput fullWidth source="last_name" />
        <TextInput fullWidth source="display_name" />
        <TextInput fullWidth source="email" />
        <DateInput fullWidth source="created_utc" disabled />
        <DateInput fullWidth source="modified_utc" disabled />
        <BooleanInput fullWidth source="is_admin" />
      </SimpleForm>
    </Edit>
  );
};
