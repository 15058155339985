import { SubmitHandler, FieldValues } from 'react-hook-form';
import {
  List,
  Datagrid,
  TextField,
  Button,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  TopToolbar,
  ListButton,
  WithRecord,
  SelectInput,
  useDataProvider,
  useRefresh,
  Identifier,
  ImageField,
  Toolbar,
  ToolbarProps,
  SaveButton,
  BooleanInput,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { Typography } from '@mui/material';

interface SpaceProps {
  id: number;
  space_key: string;
  name: string;
  logo_uri: string;
  theme_key: string;
  login_text: string;
  description: string;
  terms_of_use_uri: string;
  data_protection_uri: string;
  legal_notices_uri: string;
  ark_id: string;
  open_to_user_demo: boolean;
  modules: Array<number>;
}

const postFilters = [<TextInput label="Search" source="terms" alwaysOn />];

const { REACT_APP_API_URL } = process.env;
const themePageUrl: string = `${
  REACT_APP_API_URL === '/' ? 'http://localhost:3000/' : REACT_APP_API_URL
}themes`;

export const SpaceList = (props: SpaceProps) => {
  return (
    <List {...props} filters={postFilters}>
      <Datagrid>
        <TextField source="space_key" />
        <WithRecord
          label="Logo"
          render={(record) => (
            <>
              {record.logo_uri ? (
                <ImageField
                  source="logo_uri"
                  sx={{
                    '& img': {
                      maxWidth: 100,
                      maxHeight: 50,
                      objectFit: 'contain',
                    },
                  }}
                />
              ) : (
                <img
                  src="/assets/images/placeholder.svg"
                  alt="Logo manquant"
                  height="50"
                />
              )}
            </>
          )}
        />
        <TextField source="name" />
        <TextField source="terms_of_use_uri" />
        <EditButton />
        {/* <DeleteButton /> */}
      </Datagrid>
    </List>
  );
};

const EditActions = () => (
  <TopToolbar>
    <ListButton label="Retour à la liste" />
  </TopToolbar>
);

export const SpaceCreate = () => {
  return (
    <>
      <TextField fullWidth source="space_key" />
      <Create actions={<EditActions />}>
        <SimpleForm>
          <TextInput
            fullWidth
            source="space_key"
            required
            helperText="Minimum 4 caractères, parmi a-z 0-9 -"
          />
          <TextInput fullWidth source="name" required />
          <TextInput fullWidth source="login_text" multiline />
          <TextInput fullWidth source="description" multiline />
          <TextInput fullWidth source="logo_uri" />
          <TextInput
            fullWidth
            source="theme_key"
            helperText={
              <>
                Please get code on{' '}
                <a href={themePageUrl} target="_blank" rel="noreferrer">
                  theme keys page
                </a>
              </>
            }
          />
          <TextInput fullWidth source="terms_of_use_uri" />
          <TextInput fullWidth source="data_protection_uri" />
          <TextInput fullWidth source="legal_notices_uri" />
          <TextInput fullWidth source="ark_id" />
          <BooleanInput fullWidth source="open_to_user_demo" />
        </SimpleForm>
      </Create>
    </>
  );
};

const OnlySaveToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const SpaceEdit = (props: SpaceProps) => {
  const { id } = useParams();

  const dataProvider = useDataProvider();
  const { data: allModules } = useQuery(['admin/modules', 'getList'], () =>
    dataProvider.getListFrom('admin/modules', {
      pagination: { page: 0, perPage: 999999 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    }),
  );

  const { data: associatedModulesId } = useQuery(
    [`admin/spaces/${id}/modules`, 'getListFrom', { enabled: true }],
    () =>
      dataProvider.getListFrom(`admin/spaces/${id}/modules`, {
        pagination: { page: 0, perPage: 999999 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      }),
  );

  const associatedModules = {
    ...allModules,
    results:
      associatedModulesId && allModules && allModules?.results
        ? allModules.results.filter((module: any) =>
            associatedModulesId?.includes(module.id),
          )
        : [],
  };

  const unassociatedModules = {
    ...allModules,
    results:
      associatedModulesId && allModules && allModules?.results
        ? allModules.results.filter(
            (module: any) => !associatedModulesId?.includes(module.id),
          )
        : [],
  };

  const refresh = useRefresh();
  const { mutate: updateAssociatedModules } = useMutation(
    [`admin/spaces/${id}/modules`, 'updateListFrom'],
    (ids: Array<any>) =>
      dataProvider
        .updateListFrom(`admin/spaces/${id}/modules`, {
          data: ids,
        })
        .then(() => {
          refresh();
        }),
  );

  const handleDelete = (id: Identifier) => {
    updateAssociatedModules(
      associatedModulesId.filter((associatedId: number) => {
        return associatedId !== id;
      }),
    );
  };

  const handleSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    updateAssociatedModules([...associatedModulesId, data.id]);
  };

  return (
    <>
      <Edit actions={<EditActions />}>
        <>
          {!allModules && <>...</>}
          {allModules && (
            <>
              <SimpleForm>
                <TextInput fullWidth source="space_key" disabled />
                <TextInput fullWidth source="name" required />
                <TextInput fullWidth source="login_text" multiline />
                <TextInput fullWidth source="description" multiline />
                <TextInput fullWidth source="logo_uri" />
                <TextInput
                  fullWidth
                  source="theme_key"
                  helperText={
                    <>
                      Please get code on{' '}
                      <a href={themePageUrl} target="_blank" rel="noreferrer">
                        theme keys page
                      </a>
                    </>
                  }
                />
                <TextInput fullWidth source="terms_of_use_uri" />
                <TextInput fullWidth source="data_protection_uri" />
                <TextInput fullWidth source="legal_notices_uri" />
                <TextInput fullWidth source="ark_id" />
                <BooleanInput fullWidth source="open_to_user_demo" />
              </SimpleForm>
              <br />
              <hr />
              <br />
              <SimpleForm onSubmit={handleSubmit} toolbar={<OnlySaveToolbar />}>
                <Typography component="h2" variant="h6">
                  Associated modules :
                </Typography>
                {associatedModules.results && (
                  <Datagrid
                    data={associatedModules.results}
                    total={associatedModules.results.length}
                    sort={{ field: 'id', order: 'ASC' }}
                  >
                    <TextField source="id" />
                    <TextField source="title" />
                    <WithRecord
                      label="Delete"
                      render={(record) => (
                        <Button
                          label="Delete"
                          onClick={() => handleDelete(record.id)}
                        />
                      )}
                    />
                  </Datagrid>
                )}
                <br />
                <br />
                {unassociatedModules.results && (
                  <SelectInput
                    fullWidth
                    source="id"
                    choices={unassociatedModules.results}
                    optionText="title"
                    label="Ajouter un module"
                  />
                )}
              </SimpleForm>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </>
          )}
        </>
      </Edit>
    </>
  );
};
