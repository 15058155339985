import { AuthProvider } from 'react-admin';
import axios from 'axios';
const { REACT_APP_API_URL } = process.env;

const getProfile: () => Promise<{
  is_admin: boolean;
  id: string;
  first_name: string;
  last_name: string;
}> = () => {
  const { access_token } = JSON.parse(localStorage.getItem('auth') || '');
  return axios
    .get(REACT_APP_API_URL + 'api/profile/details', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.data;
    })
    .catch((error) => {
      throw new Error(error || 'Network error');
    });
};

const authProvider: AuthProvider = {
  login: ({ username, password }: { username: string; password: string }) => {
    return axios
      .post(
        REACT_APP_API_URL + 'api/authentication/token',
        { username, password },
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        if (!response.data.is_success) {
          throw new Error(response.data.error_code);
        }
        return response.data;
      })
      .then((auth) => {
        localStorage.setItem('auth', JSON.stringify(auth));
      })
      .then(() => {
        return getProfile();
      })
      .then((profile) => {
        if (!profile?.is_admin) {
          throw new Error('Account is not admin');
        }
        const identity = {
          id: profile.id,
          fullName: `${profile.first_name} ${profile.last_name}`,
        };
        localStorage.setItem('identity', JSON.stringify(identity));
      })
      .catch((error) => {
        throw new Error(error || 'Network error');
      });
  },
  logout: () => {
    localStorage.removeItem('identity');
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem('identity') && localStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject(),
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('identity');
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    return getProfile()
      .then((profile) => {
        if (!profile?.is_admin) {
          throw new Error('Account is not admin');
        }
        const identity = {
          id: profile.id,
          fullName: `${profile.first_name} ${profile.last_name}`,
        };
        localStorage.setItem('identity', JSON.stringify(identity));
        return identity;
      })
      .catch(() => {
        throw new Error('Network error');
      });
  },
  getPermissions: () => Promise.resolve(''),
};

export default authProvider;
