import { HttpError } from 'react-admin';
import { Options } from 'ra-core';

export const createHeadersFromOptions = (options: Options): Headers => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    })) as Headers;
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return requestHeaders;
};

export const fetchJson = (url: string, options: Options = {}) => {
  const requestHeaders = createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
    .then((response) => {
      return response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }));
    })
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        const message: string = json?.error_message
          ? `${json.error_message} :  ${json.reasons?.join(' / ')}`
          : "Une erreur inconnue s'est produite, contactez le support";
        return Promise.reject(
          new HttpError((json && json.message) || message, status, json),
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
  // .catch((error) => {
  //   console.log(error);
  // })
};
