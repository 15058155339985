import { Options } from 'ra-core';
import { fetchJson } from './fetchJson';

const httpClientAuthorization: (
  url: string,
  options?: Options | undefined,
) => Promise<{ status: number; headers: Headers; body: string; json: any }> = (
  url,
  options = { headers: new Headers() },
) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const { access_token } = JSON.parse(localStorage.getItem('auth') || '');
  (options.headers as Headers).set('Authorization', `Bearer ${access_token}`);
  return fetchJson(url, options);
};

export default httpClientAuthorization;
