import { SubmitHandler, FieldValues } from 'react-hook-form';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  Create,
  TopToolbar,
  ListButton,
  WithRecord,
  useDataProvider,
  useRefresh,
  Button,
  Toolbar,
  ToolbarProps,
  SaveButton,
  SelectInput,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { Typography } from '@mui/material';

export interface StructureProps {
  id: number;
  guid: string;
  identifier: string;
  name: string;
  created_utc: string;
  modified_utc: string;
  uai_code: string;
}

const postFilters = [<TextInput label="Search" source="terms" alwaysOn />];

export const StructureList = (props: StructureProps) => {
  return (
    <List {...props} filters={postFilters}>
      <Datagrid>
        <TextField source="identifier" />
        <TextField source="name" />
        <EditButton />
        {/* <DeleteButton /> */}
      </Datagrid>
    </List>
  );
};

const EditActions = () => (
  <TopToolbar>
    <ListButton label="Retour à la liste" />
  </TopToolbar>
);

export const StructureCreate = () => {
  return (
    <Create actions={<EditActions />}>
      <SimpleForm>
        <TextInput
          fullWidth
          source="identifier"
          required
          helperText="Minimum 4 caractères, parmi a-z A-Z 0-9 - _"
        />
        <TextInput fullWidth source="name" required />
        <TextInput fullWidth source="uai_code" />
      </SimpleForm>
    </Create>
  );
};

const OnlySaveToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const StructureEdit = (props: StructureProps) => {
  const { id } = useParams();

  const dataProvider = useDataProvider();
  const { data: allSpaces } = useQuery(['admin/spaces', 'getList'], () =>
    dataProvider.getListFrom('admin/spaces', {
      pagination: { page: 0, perPage: 999999 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    }),
  );

  const { data: associatedSpacesId } = useQuery(
    [`admin/structures/${id}/spaces`, 'getListFrom', { enabled: true }],
    () =>
      dataProvider.getListFrom(`admin/structures/${id}/spaces`, {
        pagination: { page: 0, perPage: 999999 },
        sort: { field: 'id', order: 'ASC' },
        filter: {},
      }),
  );

  const associatedSpaces = {
    ...allSpaces,
    results:
      associatedSpacesId && allSpaces && allSpaces?.results
        ? allSpaces.results.filter((space: any) => {
            return associatedSpacesId?.includes(space.space_key);
          })
        : [],
  };

  const unassociatedSpaces = {
    ...allSpaces,
    results:
      associatedSpacesId && allSpaces && allSpaces?.results
        ? allSpaces.results.filter((space: any) => {
            return !associatedSpacesId?.includes(space.space_key);
          })
        : [],
  };

  const refresh = useRefresh();
  const { mutate: updateAssociatedSpaces } = useMutation(
    [`admin/structures/${id}/spaces`, 'updateListFrom'],
    (ids: Array<any>) =>
      dataProvider
        .updateListFrom(`admin/structures/${id}/spaces`, {
          data: ids,
        })
        .then(() => {
          refresh();
        }),
  );

  const handleSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    updateAssociatedSpaces([...associatedSpacesId, data.id]);
  };
  const handleDelete = (space_key: string) => {
    updateAssociatedSpaces(
      associatedSpacesId.filter((associatedId: string) => {
        return associatedId !== space_key;
      }),
    );
  };

  return (
    <>
      <Edit actions={<EditActions />}>
        <>
          {!allSpaces && <>...</>}
          {allSpaces && (
            <>
              <SimpleForm toolbar={<OnlySaveToolbar />}>
                <TextInput fullWidth source="id" disabled />
                <TextInput
                  fullWidth
                  source="identifier"
                  required
                  helperText="Minimum 4 caractères, parmi a-z A-Z 0-9 - _"
                />
                <TextInput fullWidth source="name" required />
                <TextInput fullWidth source="uai_code" />
                <DateInput fullWidth source="created_utc" disabled />
                <DateInput fullWidth source="modified_utc" disabled />
              </SimpleForm>
              <SimpleForm onSubmit={handleSubmit} toolbar={<OnlySaveToolbar />}>
                <Typography component="h2" variant="h6">
                  Associated spaces :
                </Typography>
                {associatedSpaces.results && (
                  <Datagrid
                    data={associatedSpaces.results}
                    total={associatedSpaces.results.length}
                    sort={{ field: 'id', order: 'ASC' }}
                  >
                    {/* <TextField source="id" /> */}
                    <TextField source="space_key" label="Space" />
                    <WithRecord
                      label="Delete"
                      render={(record) => (
                        <Button
                          label="Delete"
                          onClick={() => handleDelete(record.space_key)}
                        />
                      )}
                    />
                  </Datagrid>
                )}
                <br />
                <br />
                {unassociatedSpaces.results && (
                  <SelectInput
                    fullWidth
                    source="id"
                    choices={unassociatedSpaces.results.map((space: any) => ({
                      ...space,
                      id: space.space_key,
                    }))}
                    optionText="space_key"
                    label="Ajouter un space"
                  />
                )}
              </SimpleForm>
            </>
          )}
        </>
      </Edit>
    </>
  );
};
