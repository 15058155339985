import { Admin, Resource } from 'react-admin';
import dataProvider from 'dataProvider';
import { ModuleList, ModuleEdit, ModuleCreate } from 'components/Module';
import {
  ServiceProviderList,
  ServiceProviderEdit,
  ServiceProviderCreate,
} from 'components/ServiceProvider';
import {
  StructureList,
  StructureEdit,
  StructureCreate,
} from 'components/Structure';
import { UserList, UserEdit, UserCreate } from 'components/User';
import { SpaceList, SpaceEdit, SpaceCreate } from 'components/Space';
import {
  // AccountList,
  AccountEdit,
  AccountCreate,
} from 'components/Account';
import authProvider from 'core/auth/authProvider';
import httpClientAuthorization from 'core/auth/httpClientAuthorization';

function App() {
  const { REACT_APP_API_URL } = process.env;

  return (
    <div className="App">
      <div>
        <Admin
          authProvider={authProvider}
          dataProvider={dataProvider(
            `${REACT_APP_API_URL}api`,
            httpClientAuthorization,
          )}
        >
          <Resource
            name="admin/structures"
            list={StructureList}
            edit={StructureEdit}
            create={StructureCreate}
            recordRepresentation={(record) => `${record.name}`}
          />
          <Resource
            name="admin/spaces"
            list={SpaceList}
            edit={SpaceEdit}
            create={SpaceCreate}
            recordRepresentation={(record) => `${record.space_key}`}
          />
          <Resource
            name="admin/modules"
            list={ModuleList}
            edit={ModuleEdit}
            create={ModuleCreate}
            recordRepresentation={(record) => `${record.title}`}
          />
          <Resource
            name="admin/users"
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
            recordRepresentation={(record) =>
              `${record.first_name} ${record.last_name}`
            }
          />
          <Resource
            name="admin/accounts"
            // list={AccountList}
            edit={AccountEdit}
            create={AccountCreate}
            recordRepresentation={(record) =>
              `${record.first_name} ${record.last_name}`
            }
          />
          <Resource
            name="admin/service_providers"
            list={ServiceProviderList}
            edit={ServiceProviderEdit}
            create={ServiceProviderCreate}
            recordRepresentation={(record) => `${record.service_name}`}
          />
          {/* <CustomRoutes>
            <Route path="/loginn" element={<Login onSuccess={() => {}} />} />
          </CustomRoutes> */}
        </Admin>
      </div>
    </div>
  );
}

export default App;
